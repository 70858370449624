 {
    overflow-x: hidden;
}

input {
  background: #ffffff;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.form-group {
    height: 40px;
}
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
input[readonly] {
    background-color: white !important;
    cursor: text !important;
}

/* Danilian start */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/auth/fonts/materialdesignicons.eot);
    /* For IE6-8 */
    src: local("Material Icons"), local("MaterialIcons-Regular"), url(/auth/fonts/materialdesignicons.eot#iefix) format("embedded-opentype"), url(/auth/fonts/materialdesignicons.woff) format("woff"), url(/auth/fonts/materialdesignicons.svg) format("svg"), url(/auth/fonts/materialdesignicons.ttf) format("truetype");
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}
.no-indents {
  margin: 0;
  padding: 0;
}
.toolbar {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tool-actions {
  display: flex;
  align-items: center;
}
.tool-shift {
  display: flex;
  align-items: center;
}
.total-selected {
  font: 14px 'Roboto', sans-serif;
  font-weight: 400;
  margin-left: 20px;
  margin-top: -1px;
}
.bottom-total-selected {
  font: 14px 'Roboto', sans-serif;
  font-weight: 300;
  margin-left: 20px;
  margin-top: -1px;
}
.buttons {
  margin-left: 20px;
  margin-top: -1px;
}
.buttons button {
  width: 32px;
  height: 32px;
  border: 0;
  background-color: transparent;
  color: #4F5762;
  font: 32px 'Material Icons';
  padding: 0;
  margin: 0 4px;
}
.buttons.small button {
  width: 24px !important;
  height: 24px !important;
  font: 24px 'Material Icons';
  margin: 0 2px;
}
.buttons.small {
  margin-left: 16px !important;
  display: flex;
}
.buttons button.plus:before {
  content: '';
}
.buttons button.edit:before {
  content: '';
}
.buttons button.save:before {
  content: '';
}
.buttons button.cancel:before {
  content: '';
}
.buttons button.copy:before {
  content: '';
}
.buttons button.print:before {
  content: '';
}
.buttons button.excel:before {
  content: '';
}
.buttons button.place:before {
  content: '';
}
.buttons button.trash:before {
  content: '';
}
.buttons button.layers:before {
  content: '';
}
.buttons button.refresh:before {
  content: '';
}
.buttons button.stage:before {
  content: '';
}
.tool-filter {
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-between;
  margin-right: 20px;
}
.tool-filter div {
  border: 0;
  background-color: transparent;
  color: #4F5762;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0;
  padding: 0;
}
.tool-filter div.paste:before {
  content: '';
  font: 24px 'Material Icons';
}
.tool-filter div.table:before {
  content: '';
  font: 24px 'Material Icons';
}
.tool-filter div.filter:before {
  content: '';
  font: 24px 'Material Icons';
}
.tool-filter div span:after {
  content: '';
  color: #5D6570;
  font: 18px 'Material Icons';
}

.top-table-container {
    position: absolute;
    top:70px;
    left: 0;
    right: 0;
    bottom: 0;
}

.top-table-head {
    position: absolute;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-bottom: #ddd solid 1px;
}

.top-table-head table.table {
    margin-bottom: 0;
}

.top-table-body {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: nowrap;
}

.bottom-table-container {
    position: absolute;
    top:70px;
    left: 0;
    right: 0;
    bottom: 0;
}

.bottom-table-body {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    white-space: nowrap;
}

.bottom-table-head {
    position: absolute;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-bottom: #ddd solid 1px;
}

.bottom-table-head .table {
  height: 100%;
  margin-bottom: 0;
}

.check input[type="checkbox"] {
  margin: 0;
  padding: 0;
}
.header-item {
  font: 14px 'Roboto', sans-serif;
  font-weight: 100;
  color: #595A5E;
  cursor: pointer;
}
.check,
.header-item.check {
  width: 46px !important;
  min-width: 46px !important;
  padding-left: 20px !important;
}
.id,
.header-item.id {
  min-width: 62px;
  width: 62px;
}

.header-item:hover:after {
    font: 16px 'Material Icons';
    content: '';
}

.header-item.asc-sort:hover:after {
    font: 16px 'Material Icons';
    content: '';
}

.header-item.desc-sort:hover:after {
    font: 16px 'Material Icons';
    content: '';
}

.header-item.asc-sort:after {
    font: 16px 'Material Icons';
    content: '';
}

.header-item.desc-sort:after {
    font: 16px 'Material Icons';
    content: '';
}

.top-table-body table {
  border-collapse: collapse;
}
.top-table-body td {
  font: 14px 'Roboto', sans-serif;
  font-weight: 300;
  color: #5B5C60;
  margin: 0;
  padding: 0;
  height: 48px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.top-table-body td input[type="checkbox"] {
  margin: 0;
  padding: 0;
}
.top-table-body tr {
  background-color: #ffffff;
  border-bottom: 1px solid #E5E5E5;
}

.bottom-table-body table {
  border-collapse: collapse;
}
.bottom-table-body td {
  font: 14px 'Roboto', sans-serif;
  font-weight: 300;
  color: #5B5C60;
  margin: 0;
  padding: 0;
  height: 48px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.bottom-table-body td input[type="checkbox"] {
  margin: 0;
  padding: 0;
}
.bottom-table-body tr {
  background-color: #ffffff;
  border-bottom: 1px solid #E5E5E5;
}

.detail-container-header {
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  background-color: #ffffff;
  justify-content: space-between;
}
.detail-container-header .info {
  display: flex;
  align-items: center;
  height: 100%;
}
.detail-container-header button.toggle-details {
  border: 0;
  margin-right: 15px;
  background-color: transparent;
}
.detail-container-header button.toggle-details.shown:before {
  content: '';
  font: 24px 'Material Icons';
  color: #515763;
}
.detail-container-header button.toggle-details.hidden:before {
  content: '';
  font: 24px 'Material Icons';
  color: #515763;
}
.detail-container-header div.main {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
  height: 100%;
  width: 178px;
  margin: 0;
  padding: 0 0 0 0;
  text-transform: uppercase;
  font: 12px 'Roboto';
  font-weight: 300;
  text-align: left;
  color: #9B9CA0;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
}
.detail-container-header div.main:before {
  font: 24px 'Material Icons';
  transform: scaleX(0.6);
  margin-right: 6px;
  margin-left: 12px;
  color: #4F5762;
  content: 'пЏ±';
  min-width: 26px;
}
.detail-container-header div.main:hover {
  background-color: #dedede;
}
.detail-container-header div.main:active {
  background-color: #c4c4c4;
}
.detail-container-header div.main span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.detail-container-header .caption {
  font: 14px 'Roboto', sans-serif;
  font-weight: 400;
}
.detail-container-header .caption.bold {
  font: 16px 'Roboto', sans-serif;
  font-weight: 700;
  margin: 0 10px;
}

.detail-container-body {
  width: 100%;
  display: flex;
  height: 32vh;
  position: relative;
  background-color: #ffffff;
  border-top: 1px solid #E5E5E5;
}
.detail-container-body-wrapper {
  background-color: #ffffff;
}
.no-indents {
  margin: 0;
  padding: 0;
}
.detail-container-body .main-data {
    width: 100%;
    height: 100%;
}
.detail-container-body .params-table {
  width: calc(100% -  21px  -  30px);
  max-width: calc(100% -  21px  -  30px  - 142px);
  padding: 26px 30px 26px 21px;
  overflow: hidden;
}
.detail-container-body .files-container {
  width: 142px;
  min-width: 142px;
  height: 100%;
  background-color: #f7f7f7;
}
.detail-container-body .header h3 {
  font: 14px 'Roboto', sans-serif;
  font-weight: 400;
  color: #e04d51;
  margin: 0;
  padding: 0;
}

.detail-container-body .dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.detail-container-body .dt,
.detail-container-body .dd {
  display: inline;
}
.detail-container-body .dt {
  color: #B2B3B5;
  margin: 0;
  padding: 0;
  font: 14px 'Roboto', sans-serif;
  font-weight: 300;
  white-space: nowrap;
  position: relative;
}
.detail-container-body .dt:after {
  position: absolute;
  content: '................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
  font: 14px 'Roboto', sans-serif;
  font-weight: 300;
}
.detail-container-body .dd {
  color: #000000;
  white-space: nowrap;
  z-index: 1;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font: 14px 'Roboto', sans-serif;
  font-weight: 400;
}
.filter-options-wrapper {
    display: flex;
    justify-content: space-between;
}
.filter-remove {
    font: 11px 'Roboto';
    font-weight: 300;
    text-decoration: none;
    color: #9FA0A2;
    text-transform: lowercase;
    position: relative;
    left: 20px;
    top: -20px;
    margin-bottom: -10px;
    cursor: pointer;
}
.filter-remove:before {
    content: '';
    font: 9px 'Material Icons';
    color: #9FA0A2;
    margin-right: 4px;
}
.add-case {
    font: 11px 'Roboto';
    font-weight: 300;
    text-decoration: none;
    color: #9FA0A2;
    text-transform: lowercase;
    position: relative;
    left: -20px;
    top: -20px;
    margin-bottom: -10px;
    cursor: pointer;
}
.add-case:before {
    content: '';
    font: 9px 'Material Icons';
    color: #9FA0A2;
    margin-right: 4px;
}
/*
.default-shadow {
    box-shadow: 0px 0px 20px #ECECEC;
}
#filter-window {
    display: none;
    width: auto;
    position: absolute;
    min-height: 50px;
    background-color: white;
    z-index: 105;
    box-shadow: 0px 0px 20px #ECECEC;
}
#filter-window .filter-content {
    margin: 29px 36px 29px 32px;
    width: 100%;
}
#filter-window .filter-content ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: block;
}
#filter-window .filter-content ul li {
    margin: 0px;
    padding: 0px;
    display: block;
    margin-bottom: 13px;
}
#filter-window .filter-content ul li .row-fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
#filter-window .filter-content ul li .row-fields:last-child {
    margin-bottom: 29px;
}
#filter-window .filter-content ul li .row-fields div.entry-name {
    width: calc(40% - 12px);
}
#filter-window .filter-content ul li .row-fields div.entry-name.disabled .select2-selection__rendered {
    color: #A4A4A4 !important;
}
#filter-window .filter-content ul li .row-fields div.entry-equation {
    width: calc(10% - 12px);
}
#filter-window .filter-content ul li .row-fields div.entry-value {
    width: calc(50% - 12px);
}
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container,
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container,
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-from,
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-from,
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-from,
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-to,
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-to,
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-to {
    width: calc(50% - 10px);
    height: 28px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-from:after,
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-from:after,
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-from:after,
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-to:after,
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-to:after,
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-to:after {
    content: '';
    font: 17px 'Material Icons';
    color: black;
    margin-right: 9px;
}
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-from input[type="text"],
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-from input[type="text"],
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-from input[type="text"],
#filter-window .filter-content ul li .row-fields div.entry-name div.date-range-container div.date-to input[type="text"],
#filter-window .filter-content ul li .row-fields div.entry-equation div.date-range-container div.date-to input[type="text"],
#filter-window .filter-content ul li .row-fields div.entry-value div.date-range-container div.date-to input[type="text"] {
    width: calc(100% - 33px);
    background-color: transparent;
    height: 100%;
    border: 0px;
    font: 14px 'Roboto';
    font-weight: 300;
    padding: 0px 5px;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container {
    width: 100% !important;
    padding-left: 8px;
    background-color: #f2f2f2;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection {
    border: 0px !important;
    border-radius: 0px !important;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection.select2-selection--multiple,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection.select2-selection--multiple,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection.select2-selection--multiple {
    cursor: pointer;
    min-height: 28px;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection.select2-selection--multiple:after,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection.select2-selection--multiple:after,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection.select2-selection--multiple:after {
    content: '';
    font: 18px 'Material Icons';
    color: black;
    margin-right: 2px;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered {
    display: block;
    vertical-align: middle;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search--inline input.select2-search__field,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search--inline input.select2-search__field,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search--inline input.select2-search__field {
    font: 14px 'Roboto', sans-serif;
    font-weight: 300;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice {
    margin-bottom: 0px;
    margin-top: 4px;
    border: 0px;
    background-color: transparent;
    margin-right: 16px;
    font: 14px 'Roboto', sans-serif;
    font-weight: 300;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice span.select2-selection__choice__remove,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice span.select2-selection__choice__remove,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-selection__choice span.select2-selection__choice__remove {
    float: right;
    font-size: 20px;
    line-height: 0px;
    margin-top: 8px;
    margin-left: 8px;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search.select2-search--inline,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search.select2-search--inline,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection ul.select2-selection__rendered li.select2-search.select2-search--inline {
    margin-bottom: 4px;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection span.select2-selection__rendered,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection span.select2-selection__rendered,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection span.select2-selection__rendered {
    font: 14px 'Roboto', sans-serif;
    font-weight: 300;
    color: black;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow:before,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow:before,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow:before {
    content: '‘';
    font: 18px 'Material Icons';
    color: black;
}
#filter-window .filter-content ul li .row-fields div.entry-name .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow b,
#filter-window .filter-content ul li .row-fields div.entry-equation .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow b,
#filter-window .filter-content ul li .row-fields div.entry-value .select2.select2-container span.selection span.select2-selection span.select2-selection__arrow b {
    display: none;
}
#filter-window .filter-content ul li .row-accessories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
#filter-window .filter-content ul li .row-accessories a.filter-remove {
    font: 11px 'Roboto';
    font-weight: 300;
    text-decoration: none;
    color: #9FA0A2;
    text-transform: lowercase;
}
#filter-window .filter-content ul li .row-accessories a.filter-remove:before {
    content: '';
    font: 9px 'Material Icons';
    color: #9FA0A2;
    margin-right: 4px;
}
#filter-window .filter-content .bottom-buttons {
    display: flex;
    height: 31px;
    width: auto;
    justify-content: space-between;
}
#filter-window .filter-content .bottom-buttons .search-button {
    width: 89px;
    height: 100%;
}
#filter-window .filter-content .bottom-buttons .search-button button {
    width: 100%;
    height: 100%;
    border: 1px solid #EC5A5B;
    background-color: #EC5A5B;
    color: #ffffff;
    text-transform: uppercase;
    font: 13px 'Roboto', sans-serif;
    font-weight: 300;
}
#filter-window .filter-content .bottom-buttons .save-filter-container {
    height: 100%;
    width: auto;
}
#filter-window .filter-content .bottom-buttons .save-filter-container button {
    width: 145px;
    height: 31px;
    border: 1px solid #E5E5E5;
    background-color: transparent;
    color: #000000;
}
#filter-window .filter-content .bottom-buttons .save-filter-container span {
    color: #2EB968;
    font: 12px 'Roboto', sans-serif;
    font-weight: 300;
    margin: 0px 20px 0px;
}
#filter-window .filter-content .bottom-buttons .save-filter-container span:before {
    content: '';
    font: 12px 'Material Icons';
    color: #2EB968;
    margin-right: 5px;
}
*/
/* Danilian end */

/* app2 start */

body {
    position:relative;
    font:14px 'Roboto', Arial, Myriad Pro, Tahoma;
    font-weight: 300;
    color:#444;
    text-align:left;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background:#f6f7f9;
}
html {
    text-align:left;
    height: 100%;
    margin: 0;
    padding: 0;
}

a:active, a:focus {
    outline: none;
}

header{
    width: 100%;
    position:fixed;
    z-index: 5;
    top: 0;
    left: 0;
    height: 56px;
    padding-right: 32px;
    overflow:hidden;
    background:#ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
}
header .navbar-nav>li>a {
    padding-top: 0;
    padding-bottom: 0;
    height: 56px;
    line-height: 56px;
}
header .navbar-nav>li.active {
    background: #f7f7f7;
}
header .navbar-nav>li>a,
header .navbar-nav>li.active>a{
    color: #444444;
}
header .navbar-nav>li.nav-user>a {
    color: #777777;
}

header .navbar-nav.module-selector {
    width: 240px;
    margin: 0;
}
header .navbar-nav.module-selector li {
    width: 100%;
}
header .navbar-nav.module-selector li a{
    padding-left: 25px;
    background: #eeeeee;
    transition: color .1s linear, background .1s linear;
}
header .navbar-nav.module-selector li a:hover{
    background: #dddddd;
}
header .navbar-nav.module-selector .fa{
    padding-right: 10px;
}

header .filter {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 25px 0 35px;
    box-sizing: border-box;
}
header .filter .wrap {
    padding: 1px 10px;
    border: #b7b7b7 solid 1px;
    white-space: nowrap;
}
header .filter .wrap .fa{
    color: #888;
    padding-right: 4px;
}

header .filter .wrap input{
    outline: none;
    width: 30vw;
    border: none;
}
header .filter button {
    padding: 0;
    background: none;
    border: none;
    outline: none;
}

header .filter .wrap button:hover .fa {
    color: #555;
}

#app-layout{
    position: absolute;
    display:block;
    top:56px;
    right:0;
    bottom:0;
    left:0;
    text-align:left;
    z-index:2;
    box-sizing:border-box;
}

#sidebar-toggler {
    display:block;
    position:fixed;
    vertical-align:center;
    width:40px;
    height:100%;
    top:0;
    left:0;
    bottom:0;
    padding-bottom:50px;
    box-sizing:border-box;
    background:#292C33;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.39);
    z-index:104;
    cursor: pointer;
}

#sidebar-toggler>.tail {
    display: block;
    position: absolute;
    height: 12px;
    width: 12px;
    top: 50%;
    bottom: -8px;
    right: 15px;
    margin-top: -6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    border: #999999 solid;
    border-width: 0 1px 1px 0;
    z-index: 1;
    transition: transform 0.3s;
}

#sidebar-toggler>.tail.active {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transition: transform 0.3s;
}

#sidebar{
    display:block;
    position:absolute;
    width:200px;
    top:0;
    left:40px;
    bottom:0;
    padding-bottom:50px;
    box-sizing:border-box;
    background:#292C33;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.39);
    z-index:4;
}

#sidebar ul{
    margin:0;
    list-style:none;
    padding:0 0 25px;
}

#sidebar>ul>li{
    margin:0;
    padding: 0;
    border-bottom:1px solid #363B41;
}

#sidebar ul>li>a{
    display:block;
    box-sizing:border-box;
    position:relative;
    width:100%;
    padding: 21px 32px 21px 24px;
    color:#6C7177;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration:none;
    transition: color .1s linear, background .1s linear, border .1s linear;
}

#sidebar>ul>li.active>a,
#sidebar>ul:first-child>li>a:hover{
    color: #aeb4ba;
}

#sidebar>ul>li>a>.tail {
    display: block;
    position: absolute;
    height: 12px;
    width: 12px;
    top: 50%;
    bottom: -8px;
    right: 15px;
    margin-top: -6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    border: #999999 solid;
    border-width: 0 1px 1px 0;
    z-index: 1;
    transition: transform 0.3s;
}
#sidebar>ul>li.active>a>.tail{
    border-color: #aeb4ba;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: transform 0.3s;
}

#sidebar>ul li>ul{
    display: none;
}
#sidebar>ul li.active>ul {
    display: block;
}

#sidebar>ul ul>li>a{
    padding: 10px 32px 10px 24px;
    color: #bfbfbf;
    text-transform: none;
}

#sidebar>ul ul>li>a:hover{
    color: #ffffff;
}

#sidebar>ul ul>li.active>a {
    color: #ffffff;
    background: #1c1f26;
}

#sidebar.ps-container > .ps-scrollbar-y-rail {
    width: 5px;
    right: 2px;
}
#sidebar.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 5px;
}

#content{
    position: absolute;
    min-width: 784px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 240px;
    padding: 0;
    background:#ebebeb;
    color:#444;
    overflow: hidden;
    /*overflow-y: scroll;*/
}

.panel {
    position: relative;
    background-color:#ffffff;
    /*border:#d7d7d7 solid 1px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
    margin: 0;
    border-radius: 0;
    box-sizing: border-box;
}

.panel.panel-table {
    height: 60%;
    overflow: hidden;
    border-bottom: #d7d7d7 solid 1px;
}
.panel.panel-details {
    height: 40%;
    overflow: hidden;
}

.panel-container-vertical {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    height: 100%;
    overflow: hidden;
}

.panel-top {
    position: relative;
    flex: 0 0 auto;
    padding: 10px;
    height: 60%;
    min-height: 10%;
    max-height: 90%;
    width: 100%;
    background: #ffffff;
    color: black;
}

.splitter-horizontal {
    flex: 0 0 auto;
    height: 18px;
    background: url(/auth/img/hsizegrip.png) center center no-repeat #535353;
    cursor: row-resize;
    z-index: 100;
}

.panel-bottom {
    position: relative;
    flex: 1 1 auto;
    /* resizable */
    padding: 10px;
    height: 40%;
    background: #eee;
}

pre {
    margin: 20px;
    padding: 10px;
    background: #eee;
    border: 1px solid silver;
    border-radius: 4px;
    overflow: auto;
}

.resizer {
    position: absolute;
    top: 0;
    right: -8px;
    bottom: 0;
    left: auto;
    width: 16px;
    cursor: col-resize;
}

#search-modal {
    display: none;
    position: absolute;
    top: 5px;
    left: 274px;
    z-index: 110;

}
#search-modal>.wrapper{
    min-width: 700px;
    padding: 25px 20px;
    border: #777 solid 1px;
    position: relative;
    background: #ffffff;
}

#search-modal .close {
    position: absolute;
    padding: 5px 20px 0 0;
    top: 0;
    right: 0;
    background: none;
    border: none;
    outline: none;
}

.table-item {
    cursor: pointer;
}

.top-table-body tr.table-item.active-row {
    background: #292C33;
}

.top-table-body tr.table-item.active-row td {
    color: #ffffff;
}

.bottom-table-body tr.table-item.active-row {
    background: #292C33;
}

.bottom-table-body tr.table-item.active-row td {
    color: #ffffff;
}

#filter-form {
    padding: 20px 0;
    position: relative;
}

#form-items {
    display: none;
}

.fi{
    padding: 5px 0;
}
.fi .title {
    font-weight: 700;
}
.fi .item{
    width: 150px;
}
.fi .remove{
    cursor: pointer;
    text-decoration: dotted;
}



.editable-item {
    padding: 5px 0;
}

.editable-item>.title{
    display: inline-block;
}

.editable-item>.title .ei-text{
    font-weight: bold;
}

.editable-item>.title .ei-edit{
    display: none;
}

.editable-item>.title:hover .ei-edit{
    display: inline-block;
}

.editable-item>.form{
    display: none;
}

.editable-item>.form .ei-value{
    width: 150px;
}

.editable-item .fa{
    cursor: pointer;
}

.dropdown-menu.open {
    max-width:200px;
}

#create-modal-loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
}

.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    width:100%;
    height:auto;
}
/*.loading-helper {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}*/

/* app2 end */
